body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  
  #cover-spin {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgb(255 255 255 / 49%);
      z-index: 9999999;
      /* display: none; */
  }
  .loader {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 48%;
    top: 40%;
    background: #05243d;
    border-radius: 50%;
    transform: rotate(45deg);
    animation: rotate 2s linear infinite;
  }
  .loader:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15px;
    height: 30px;
    background: #FF3D00;
    transform: skew(5deg , 60deg) translate(-50%, -5%);
  }

  .loader:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FFF;
    transform:translate(-50% , -50%);
  }

  @keyframes rotate {
    0% { transform: rotate(45deg)}
    30% , 50% , 70%  { transform: rotate(230deg)}
    40% , 60% , 80% { transform: rotate(240deg)}
    100% {  transform: rotate(245deg)}
 }
/* Embla Carousals CSS for Partners Section*/
.partners .embla {
  overflow: hidden;
}
.partners .embla__container {
  display: flex;
}
.partners .embla__slide {
  flex: 0 0 auto;
  min-width: 0;
}

/* Embla Carousals CSS for Reviews Section*/
.top-review .embla {
  overflow: hidden;
}
.top-review .embla__container {
  display: flex;
}
.top-review .embla__slide {
  flex: 0 0 auto;
  min-width: 0;
}

/* Embla Carousals CSS for Destinations Section*/
.top-destinations .embla__slide {
  flex: 0 0 auto;
  min-width: 0;
}


.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 30rem;
  /* padding: 1.6rem; */
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla-thumbs {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 9rem;
  margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport {
  overflow: hidden;
}
.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide {
  flex: 0 0 28%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
  position: relative;
}
@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 18%;
  }
}
.embla-thumbs__slide__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  opacity: 0.2;
  transition: opacity 0.2s;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__button {
  opacity: 1;
}
.embla-thumbs__slide__img {
  display: block;
  height: var(--thumbs-slide-height);
  width: 100%;
  object-fit: cover;
}
.embla-thumbs__slide__number {
  width: 3rem;
  height: 3rem;
  z-index: 1;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 3rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla-thumbs__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



.home{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
  .overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: transparent;   */
  top: 0;
  bottom: 0;
  z-index: 1;
  
  }
  video{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }
  .home-banner{
    background: #ffffffeb;
    border-radius: 10px;
    background-image: url('../public/assets/images/home/1200X478.5 PX-03.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .home-banner-about{
    padding: 10px 30px;
    height: 300px;

  }
  .home-banner-form{
    padding: 0px;
  }
  .why-us{
    background:#ef4723;
  }



  .login-box{
    box-shadow: none;
    padding: 0;
    display: inline-block;
    width: 100%;
    background: #05243d;
    padding: 20px;
  }
  .login-div .login-box .input-box{
    max-width: 300px;
    margin: 0 auto;
  }
  .login-box .input-box i{
    position: absolute;
    left: 10px;
    top: 16px;
    z-index: 1;
    color: #f06142;
  }
  .login-box a{
    color: #fff;
  }
  .login-box a:hover{
    color: #ef4723;
  }

  @media (max-width: 991px) {

    .home-banner{
      margin: 10px;
      background-image: none;
    }
    .home-banner-about{
      display: none;
    }
    .home-banner .login-box{
      
      background: #05243d9c;
      
    }
  }
  .signin-header{
    background: #05243d;
    
  }
  .signin-footer{
    background: #05243d;
    
  }
  .login-div
  {
    max-width: 600px;
    width:100%;
    padding: 80px;
    margin: 0 auto;
    text-align: center;
  }
  
  .login-div .login-box{
    border-radius: 20px;
  }
  .signup-div .login-box{
    border-radius: 20px;
  }

  .login-div .sidebar-item h3:before {
  left: 44%;
  }
  
  .signup-div
  {
    max-width:700px;
    width: 100%;
    padding: 80px;
    margin: 0 auto;
    text-align: center;
  }
  .signup-div .sidebar-item h3:before {
    left: 44%;
  }
  @media (max-width: 991px) {
    .signup-div
    {
    
      padding: 10px;
   
    }
    .login-div
    {
    
      padding: 10px;
   
    }
    
  }
  /* .stickyBar .signin-header{
    background: #05243d;
  }
  .stickyBar .signin-footer{
    background: #05243d;
  }
  .stickyBar .top-spacer {
    height: 50vh;
  }
  
  .stickyBar .bottom-spacer {
    height: 110vh;
  }
  
  .stickyBar .sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  
  .stickyBar .header {
    display: flex;
    justify-content: center;
    background-color: #fff;
  }
  
  .stickyBar .header_link {
    padding: 20px;
    color: #314d4a;
    font-weight: bold;
    border: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    outline: none;
  }
  
  .stickyBar .selected {
    border-bottom: 3px solid #11bb9a;
    color: #11bb9a;
  }
  
  .stickyBar .section {
    height: 40vh;
  }
  
  .stickyBar #Leadership {
    background-color: #a388e8;
  }
  
  .stickyBar #Providers {
    background-color: #f4769e;
  }
  
  .stickyBar #Operations {
    background-color: #8face0;
  } */
  .headerSearchItem {
    background: white;
    padding: 10px;
    border-radius: 10px;
  }
  .headerSearchItem span {
    color:#444444;
    margin-left: 3px;
  }
  .headerSearchItem i {
    color:#ef4723;
    font-weight: 600;
  }
  .headerSearchItem .date{
    position: absolute;
    left: 15px;
    z-index: 3;
    top: 93px;
  }
  .headerSearchItem .options {
    z-index: 2;
    position: relative;
    background-color: white;
    color: gray;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
  
  .headerSearchItem .optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }
  
  .headerSearchItem .optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: black;
  }
  
  .headerSearchItem .optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid #05243d;
    color: #05243d;
    cursor: pointer;
    background-color: white;
  }
  
  .headerSearchItem .optionCounterButton:disabled {
    cursor: not-allowed;
  }
  
  .destination-dropdown {
    position: relative;
    color: #333;
    cursor: default;
  }
  
  .destination-dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }
  
  .destination-dropdown .arrow.open {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
  
  .destination-dropdown .selected-value input {
    /* line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%; */
    /* border-radius: 10px 10px 0 0; */
  }
  
  .destination-dropdown .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: 2px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    border-radius: 10px;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .destination-dropdown .options.open {
    display: block;
  }
  
  .destination-dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .destination-dropdown .option.selected,
  .destination-dropdown .option:hover {
    background-color: #f2f9fc;
    color: #333;
  }
  
  